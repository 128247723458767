import { Routes } from '@angular/router';

export const AuthRoutes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'login' },
	{
		path: 'login',
		data: { type: 'login' },
		loadComponent: () =>
			import('../components/container/container.component').then(c => c.ContainerComponent)
	},
	{
		path: 'logout',
		data: { type: 'logout' },
		loadComponent: () =>
			import('../components/logout/logout.component').then(c => c.LogoutComponent)
	},
	{
		path: 'reset',
		data: { type: 'reset' },
		loadComponent: () =>
			import('../components/container/container.component').then(c => c.ContainerComponent)
	},
	{
		path: 'create/:id',
		data: { type: 'create' },
		loadComponent: () =>
			import('../components/container/container.component').then(c => c.ContainerComponent)
	},
	{
		path: 'confirm/:id',
		data: { type: 'confirm' },
		loadComponent: () =>
			import('../components/container/container.component').then(c => c.ContainerComponent)
	},
	{
		path: 'confirm',
		pathMatch: 'full',
		redirectTo: 'confirm/invalid-id'
	},
	{ path: '**', pathMatch: 'full', redirectTo: 'login' }
];
