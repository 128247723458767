import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as AuthEffects from './+state/auth.effects';
import { authFeature } from './+state/auth.state';
import { AuthInterceptor } from './interceptors/auth.interceptor';
// NGRX
import { AUTH_URL, AuthInitProvider, REDIRECT } from './services/auth.service';

export function provideAuthConfig(options: {
	redirect?: string[];
	authUrl?: string;
}): EnvironmentProviders {
	return makeEnvironmentProviders([
		AuthInitProvider,
		provideState(authFeature),
		provideEffects([AuthEffects]),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: REDIRECT,
			useValue: options?.redirect || []
		},
		{
			provide: AUTH_URL,
			useValue: options?.authUrl || ''
		}
	]);
}
