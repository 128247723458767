import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';

import { UserRoles, YunoUserRoles } from '@yuno/api/interface';

import { authFeature } from '../+state/auth.state';

export const xkpMemberResolver: ResolveFn<boolean> = () => {
	const store = inject(Store);
	return store.select(authFeature.XKPuser);
};

export const userRoleResolver: ResolveFn<UserRoles | undefined> = () => {
	const store = inject(Store);
	return store.select(authFeature.userRole);
};

export const appRoleResolver: ResolveFn<YunoUserRoles | undefined> = () => {
	const store = inject(Store);
	return store.select(authFeature.appRole);
};
