import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { AuthFacade } from '../+state/auth.facade';
import { StateValidators } from '../+state/auth.models';
import { authFeature } from '../+state/auth.state';

export const RoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
	const router = inject(Router);
	const store = inject(Store);
	const authFacade = inject(AuthFacade);

	return store.select(authFeature.appRoleState).pipe(
		tap(state => {
			// if the Status is PENDING or VALIDATING or current APPID is not the same
			// reset State to PENDING
			if (
				state.appState &&
				state.appState?.appId !== route.params['appId'] &&
				![StateValidators.PENDING, StateValidators.VALIDATING].includes(
					state.appStateStatus
				)
			) {
				authFacade.appRolePending();
			}

			// WHEN PENDING SEND VALIDATION REQUEST TO SERVER
			if (state.appStateStatus === StateValidators.PENDING) {
				authFacade.appRoleVerify(route.params['appId']);
			}
		}),
		// HOLD WHEN PENDING OR VALIDATING
		filter(state => {
			const isAppStateValid =
				state.appStateStatus !== StateValidators.PENDING &&
				state.appStateStatus !== StateValidators.VALIDATING;

			const sameApp =
				!route.params['appId'] || state.appState?.appId === route.params['appId'];

			return sameApp && isAppStateValid;
		}),
		// WHEN FAILED OR SUCCESS
		switchMap(state => {
			if (state.appStateStatus === StateValidators.FAILED) {
				router.navigate(['/']);
				return of(false);
			}

			return of(true);
		})
	);
};
