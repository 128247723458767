import { inject } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { generateRouteParams, routeParamsOrder } from '@yuno/shared/helpers';

import { AuthFacade } from '../+state/auth.facade';

export const AuthGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
): Observable<boolean> => {
	const router = inject(Router);
	const auth = inject(AuthFacade);
	const activatedRoute = inject(ActivatedRoute);

	return auth.isAuthenticated$.pipe(
		take(1),
		tap(isAuthenticated => {
			if (!isAuthenticated) {
				let url: string[] = [
					...generateRouteParams(
						route.params['appname'] || undefined,
						route.params,
						routeParamsOrder
					)
				];

				/* Merge all with auth / login routes */
				url = [...url, 'auth', 'login'];

				/* Navigate to the Login Page */
				router.navigate(url, {
					relativeTo: activatedRoute,
					queryParams: { returnUrl: state.url }
				});
			}
		})
	);
};
